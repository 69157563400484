import { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Utils from './Utils';
import { Alert, Button, Card, CardBody, Container, Form, FormGroup, Label, Input, FormFeedback, CardHeader } from 'reactstrap';
import { setCredentials, selectLoggedIn } from "./store/slices/authSlice";

export default function Login() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState({});
    const navigate = useNavigate();
    const loggedIn = useSelector(selectLoggedIn);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleLogin = (event) => {
        setError({});
        event.preventDefault();
        Utils.api('auth/login', {
            method: 'POST',
            body: {
                email: email, 
                password: password, 
            }
        }).then(async response => {
            await dispatch(setCredentials({ token: response.token, user: response.user, company: response.company }));
            navigate('/');
        }).catch(error => {
            setError(error.response.data);
        });
    }

    if(loggedIn) {
        return <Navigate replace to="/" />;
    }

    return (
        <Container className='vh-100 d-flex flex-column align-items-center justify-content-center'>
            
            <div className='col-sm-6 col-md-5 col-lg-3'>
            { error?.message ? <Alert color='danger'>{ error.message }</Alert> : null }
            <Card >
                <CardHeader>
                    cabinet
                </CardHeader>
                <CardBody>
                    <Form onSubmit={ handleLogin }>
                        <FormGroup>
                            <Label for="email">
                            Email
                            </Label>
                            <Input
                            id="email"
                            name="email"
                            placeholder="Correo Electrónico"
                            type='text' value={ email } onChange={ handleEmailChange }
                            invalid={ error?.errors?.email }
                            />
                            { error?.errors?.email ? <FormFeedback>{ error?.errors?.email[0] }</FormFeedback> : null }
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">
                            Password
                            </Label>
                            <Input
                            id="password"
                            name="password"
                            placeholder="Password"
                            type='password' value={ password } onChange={ handlePasswordChange }
                            invalid={ error?.errors?.password }
                            />
                            { error?.errors?.password ? <FormFeedback>{ error?.errors?.password[0] }</FormFeedback> : null }
                        </FormGroup>
                        
                            
                        <div>
                            <Button block color='primary' type='submit'>Iniciar Sesión</Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
            </div>
        </Container>
    );
}

