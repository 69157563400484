import { createSlice } from "@reduxjs/toolkit";
import { saveState, loadState } from "../../localStorage";
import Utils from "../../Utils";

const initialState = {
    loggedIn: false,
    token: null,
    user: {},
    company: {}
};

// Config slice
export const authSlice = createSlice({
    name: "auth",
    initialState: loadState('user') || initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { token, user, company } = action.payload;
            state.loggedIn = true;
            state.token = token;
            state.user = user;
            state.company = company;
            Utils.setBearerToken(token);
            saveState(state, 'user');
        },
        logout: (state) => {
            state.loggedIn = false;
            state.token = undefined;
            state.user = {};
            state.company = {};
            Utils.setBearerToken("");
            saveState(state, 'user');
        },
        updateUser: (state, action) => {
            const { user } = action.payload;
            state.user = user;
            saveState(state, 'user');
        },
        updateCompany: (state, action) => {
            const { company } = action.payload;
            state.company = company;
            saveState(state, 'user');
        }
    }
});

// Export actions
export const { setCredentials, logout, updateUser, updateCompany } = authSlice.actions;

// Select state user from slice
export const selectUser = state => state.auth.user;
export const selectCompany = state => state.auth.company;
export const selectLoggedIn = state => state.auth.loggedIn;

// Should deprecate
export const selectToken= state => state.auth.token;

// Export reducer
export default authSlice.reducer;