import { useState, useEffect } from 'react';
import { Table, Nav, NavItem, NavLink, Button, Row, Col, Card, CardBody, CardFooter, ButtonGroup, Input, ListGroup, ListGroupItem, Alert, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import './App.css';
import Utils from './Utils';
import { Outlet, Link, useSearchParams, useParams, NavLink as RNavLink, useNavigate, Routes, Route } from 'react-router-dom';
import Paginator from './Components/Paginator';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
const dayjs = require('dayjs')
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Projects() {

    return (
        <Outlet />
    )
}

export function List() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [projects, setProjects] = useState([]);
    const [pages, setPages] = useState(1)
  
    useEffect(() => {
      Utils.api('projects', {
        method: 'GET',
        params: { page: searchParams.get("page") || 1 }
      }).then((response) => {
        setProjects(response.data);
        setPages(response.last_page);
      });
    }, [searchParams]);
  
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2>Proyectos</h2>
          <Button tag={RNavLink} to='/projects/create' color='primary'>Crear</Button>
        </div>
        <Table bordered>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Cliente</th>
              <th>Manager</th>
            </tr>
          </thead>
          <tbody>
          {projects.map(p => <tr>
            <td><Link to={'/projects/' + p.id}>{p.name}</Link></td>
            <td><Link to={'/projects/' + p.id}>{p.client}</Link></td>
            <td><Link to={'/projects/' + p.id}>{p.manager.name}</Link></td>
          </tr>)}
          </tbody>
        </Table>
        <Paginator baseUrl={`/projects`} pages={pages} current={searchParams.get("page") || 1} />
      </div>
    )
  }

  export function Create() {

    const [users, setUsers] = useState([]);
    const [name, setName] = useState("");
    const [client, setClient] = useState("");
    const [manager, setManager] = useState({});
    const [error, setError] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
      Utils.api('users', {
        params: { role: 'manager' }
      }).then(response => {
        setUsers(response);
      })
    }, []);

    const saveProject = e => {
      e.preventDefault();
      Utils.api('projects', {
        method: 'POST',
        body: {
          name: name,
          client: client,
          manager_id: manager.id
        }
      }).then(response => {
        navigate('/projects');
      }).catch(error => {
        setError(error.response.data);
      });
    }

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2>Crear proyecto</h2>
          <Button tag={RNavLink} to='/projects' color='link'>Regresar a la lista</Button>
        </div>
        { error?.message ? <Alert color='danger'>{ error.message }</Alert> : null }
        <Form onSubmit={ saveProject }>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="name">
                  Nombre
                </Label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Escribe el nombre del proyecto"
                  type="text"
                  value={ name }
                  onChange={ e => setName(e.target.value) }
                  invalid={ error.errors?.name }
                />
                { error?.errors?.name ? <FormFeedback>{ error?.errors?.name[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="client">
                  Cliente
                </Label>
                <Input
                  id="client"
                  name="client"
                  placeholder="Escribe el nombre del cliente"
                  type="text"
                  value={ client }
                  onChange={ e => setClient(e.target.value) }
                  invalid={ error.errors?.client }
                />
                { error?.errors?.client ? <FormFeedback>{ error?.errors?.client[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="manager_id">
                  Manager
                </Label>
                <Input
                  id="manager_id"
                  name="manager_id"
                  type="select"
                  value={ manager?.id }
                  onChange={ e => setManager(users.find(u => u.id == e.target.value)) }
                  invalid={ error.errors?.manager_id }
                >
                  <option value=''>Selecciona un usuario</option>
                  { users.map(u => <option value={ u.id }>{ u.name }</option>) }
                </Input>
                { error?.errors?.manager_id ? <FormFeedback>{ error?.errors?.manager_id[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex justify-content-end'>
              <Button color='primary'>Guardar</Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }

  
  export function Detail() {

    const [project, setProject] = useState([]);
    let { projectId } = useParams();

    useEffect(() => {
      Utils.api(`projects/${projectId}`, {
        method: 'GET'
      }).then((response) => {
        setProject(response);
      });
    }, []);

    return (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h2>{ project.name }</h2>
          </div>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink end tag={RNavLink} to={`/projects/${projectId}`}>
                  Información General
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RNavLink} to={`/projects/${projectId}/approvals`}>
                  Aprobación de horas
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className='mt-4'>
            <Routes>
              <Route exact path="/" element={<Information project={ project }/>} />
              <Route exact path="/approvals" element={<Approvals project={ project }/>} />
              
            </Routes>
          </div>
        </>
    )
  }

  export function Information(props) {
    return (
      <div>
        <Row>
          <Col>
            <label className='text-muted small'>Fecha</label>
            <p>{ props.project.name }</p>
          </Col>
          <Col>
            <label className='text-muted small'>Usuario</label>
            <p>{ props.project.client }</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='text-muted small'>Manager</label>
            <p>{ props.project.manager?.name }</p>
          </Col>
          
        </Row>
        </div>
    )
  }
  
  
  export function Effort(props) {
    
    const [effort, setEffort] = useState([]);

    const [from, setFrom] = useState("");

    let { projectId } = useParams();

    useEffect(() => {
      console.log(from);
      Utils.api(`effort`, {
        method: 'GET',
        params: {
          from_date: from || '2023-11-11'
        }
      }).then((response) => {
        setEffort(response);
      });
    }, [from]);
    
    const labels = Object.keys(effort);

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Chart.js Line Chart',
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: [1,2,3,4,5,6,7,8,9,10,11,12],
          // borderColor: 'rgb(255, 99, 132)',
          // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Dataset 2',
          data: [0, 1,2,3,4,5,6,7,8,9,10,11],
          // borderColor: 'rgb(53, 162, 235)',
          // backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };
    return (
      <div>

        <Form>
          <Row className="row-cols-lg-auto g-3 align-items-center">
            <Col>
              <Label
                className="visually-hidden"
                for="filter_from"
              >
                Desde la fecha
              </Label>
              <Input
                id="filter_from"
                name="filter_from"
                type="date"
                value={from}
                onChange={ e => setFrom(e.target.value) }
              />
            </Col>
            <Col>
              <Label
                className="visually-hidden"
                for="filter_until"
              >
                Hasta la fecha
              </Label>
              <Input
                id="filter_until"
                name="filter_until"
                type="date"
              />
            </Col>
            <Col>
              <Label
                className="visually-hidden"
                for="filter_type"
              >
                Tipo de horas
              </Label>
              <Input
                id="filter_type"
                name="filter_type"
                type="select"
              >
                <option>Todas las horas</option>
                <option>Aprobadas</option>
                <option>Por aprobar</option>
              </Input>
            </Col>
            <Col>
              <Label
                className="visually-hidden"
                for="filter_group_by"
              >
                Agrupado por
              </Label>
              <Input
                id="filter_group_by"
                name="filter_group_by"
                type="select"
              >
                <option>Cuarto</option>
                <option>Mes</option>
                <option>Semana</option>
                <option>Día</option>
              </Input>
            </Col>
          </Row>
        </Form>

        <Line options={options} data={data} />
      </div>
    )
  }

  export function Approvals(props) {

    const [project, setProject] = useState(props.project);
    const [timelogs, setTimelogs] = useState([]);
    const [users, setUsers] = useState([]);
    let { projectId } = useParams();
    
    useEffect(() => {
      Utils.api(`projects/${projectId}/timelogs?approved=0`, {
        method: 'GET'
      }).then((response) => {
        setTimelogs(response.map(t => ({...t, selected: false})));
      });
    }, [projectId]);

    useEffect(() => {
      setUsers(timelogs.reduce((users, t) => {
        if(!users.includes(t.user.name) && t.selected) {
          users.push(t.user.name)
        }
        return users;
      }, []))
    }, [timelogs]);
    
    const handleTimelogSelection = e => {
      var t = timelogs.map(t => {
        if(e.target.value == t.id) {
          t.selected = e.target.checked;
        }
        return t;
      })
      setTimelogs(t);
    }

    const handleSelectAllTimelogs = e => {
      var t = timelogs.map(t => ({...t, selected: true}));
      setTimelogs(t);
    }

    const handleDeselectAllTimelogs = e => {
      var t = timelogs.map(t => ({...t, selected: false}));
      setTimelogs(t);
    }

    const handleTimelogApprove = e => {
      Utils.api(`timelogs/approve`, {
        method: 'POST',
        body: {
          id: timelogs.filter(t => t.selected).map(t => t.id)
        }
      }).then(response => {
        setTimelogs(timelogs.filter(t => !response.includes(t.id)))
      });
    }

    return (
      <Row>
        <Col md='9'>
          <div className='d-flex justify-content-end mb-3'>
            <ButtonGroup>
              <Button variant='primary' outline onClick={handleSelectAllTimelogs}>Todos</Button>  
              <Button variant='primary' outline onClick={handleDeselectAllTimelogs}>Ninguno</Button>
            </ButtonGroup>
          </div>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Usuario</th>
                <th>Fecha</th>
                <th>Tiempo</th>
                <th>Descripción</th>
                <th>Tarifa</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              { timelogs.map(t => <tr key={`t-${t.id}`}>
                <td>
                  <Input id={`t-${t.id}`} type='checkbox' checked={ t.selected } value={ t.id } onChange={ handleTimelogSelection }/>
                </td>
                <td>
                  <label for={`t-${t.id}`}>{ t.user.name }</label>
                </td>
                <td>
                  <label for={`t-${t.id}`}>{ t.date }</label>
                </td>
                <td>
                  <label for={`t-${t.id}`}>{ dayjs.duration(t.time, 'minutes').format('H:mm') }</label>
                </td>
                <td>
                  { t.description }
                </td>
                <td className='text-end'>
                  $ { t.rate }
                </td>
                <td className='text-end'>
                  $ { t.total }
                </td>
                </tr>) }
            </tbody>
          </Table>
        </Col>
        <Col>
          <Card>
            <CardBody className='p-0'>
              { users.length > 0 ?
              <ListGroup flush>
                { users.map(u => 
                  <ListGroupItem className='bg-transparent'>
                    <div><span className='fw-bold'>{ u }</span></div>
                    <div className='d-flex justify-content-between'>
                      <div>
                        { Math.floor(dayjs.duration(timelogs.reduce((c, t) => t.selected && t.user.name === u ? c+t.time : c, 0), 'minutes').asHours()) }:
                        { dayjs.duration(timelogs.reduce((c, t) => t.selected && t.user.name === u ? c+t.time : c, 0), 'minutes').format('mm') }
                        <span className="ms-2">({ dayjs.duration(timelogs.reduce((c, t) => t.selected && t.user.name === u ? c+t.time : c, 0), 'minutes').asHours().toFixed(2) }h)</span>
                      </div>
                      <div>
                        $ { timelogs.reduce((c, t) => t.selected && t.user.name === u ? c+parseFloat(t.total) : c, 0).toFixed(2) }
                      </div>
                    </div>
                  </ListGroupItem>
                ) }
              </ListGroup>
              :
              <Alert className='mb-0 text-center' color='light'>No hay timelogs seleccionados</Alert>
              }
            </CardBody>
            <CardFooter>
                <Button block variant='primary' onClick={ handleTimelogApprove }>Aprobar</Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    )
  }