import { Alert, ButtonToolbar, ButtonGroup, Button, Badge, Modal, ModalBody, ModalFooter, ModalHeader, Form, Row, Col, Input, FormGroup, Label, ListGroup, ListGroupItem, FormFeedback, Card, CardBody, Table } from 'reactstrap';
import { useState, useEffect } from 'react';
import './App.css';
import Utils from './Utils';
const dayjs = require('dayjs')
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export function Effort() {

    const [effort, setEffort] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [isApproved, setIsApproved] = useState(null);
    

    const runEffortReport = () => {

        var params = {
            from_date: fromDate,
            to_date: toDate,
        };

        if (isApproved !== null && isApproved !== "") {
            params.is_approved = isApproved;
        }

        Utils.api('reports/effort', {
            params: params
          }).then(response => {
            setEffort(response)
          }).catch(error => {
            
          })
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h2>Reporte de Esfuerzo</h2>
            </div>
            <Card>
                <CardBody>
                    <Form>
                        <FormGroup row>
                            <Col md={4}>
                                <FormGroup floating>
                                    <Input placeholder='but why' type='date'
                                        value={ fromDate }
                                        onChange={ e => setFromDate(e.target.value) }
                                        />
                                    <Label>Filtrar desde</Label>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup floating>
                                    <Input placeholder='but why' type='date'
                                        value={ toDate }
                                        onChange={ e => setToDate(e.target.value) }
                                        />
                                    <Label>Filtrar hasta</Label>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup floating>
                                    <Input type='select'
                                        value={ isApproved }
                                        onChange={ e => setIsApproved(e.target.value) }
                                        >
                                        <option value={""}>Todas las horas</option>
                                        <option value={1}>Únicamente aprobadas</option>
                                        <option value={0}>Por aprobar</option>
                                    </Input>
                                    <Label>Tipo</Label>
                                </FormGroup>
                            </Col>
                        </FormGroup>
                        <div className='d-flex justify-content-end'>
                            <Button type='button' color='secondary'>Limpiar</Button>    
                            <Button className='ms-2' type='button' color='primary' onClick={ runEffortReport }>Consultar</Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
            <Row>
                <Col>
                    <Table bordered className='my-4'>
                        <thead>
                            <tr>
                                <th>Proyecto</th>
                                <th>Usuario</th>
                                <th>Tipo</th>
                                <th>Costo</th>
                                <th className='text-end'>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            { effort.map(e => <tr>
                                <td>{ e.project.name }</td>
                                <td>{ e.user.name }</td>
                                <td>{ e.is_approved ? 'Aprobadas' : 'Por aprobar ' }</td>
                                <td className='text-end'>$ { e.total }</td>
                                <td className='text-end'>
                                    { Math.floor(dayjs.duration(e.time, 'minutes').asHours()) }:
                                    { dayjs.duration(e.time, 'minutes').format('mm') }
                                    <span className="ms-2 text-muted">({ dayjs.duration(e.time, 'minutes').asHours().toFixed(2) }h)</span>
                                </td>
                            </tr>) }
                            <tr>
                                <td colSpan={3} className='text-end'>
                                    <b>Totales:</b>
                                </td>
                                <td className='text-end'>
                                    $ { effort.reduce((prev, e) => (prev + parseFloat(e.total)), 0).toFixed(2) }
                                </td>
                                <td className='text-end'>
                                    { Math.floor(dayjs.duration(effort.reduce((prev, e) => (prev + parseInt(e.time)), 0), 'minutes').asHours()) }:
                                    { dayjs.duration(effort.reduce((prev, e) => (prev + parseInt(e.time)), 0), 'minutes').format('mm') }
                                    <span className="ms-2 text-muted">({ dayjs.duration(effort.reduce((prev, e) => (prev + parseInt(e.time)), 0), 'minutes').asHours().toFixed(2) }h)</span>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
}