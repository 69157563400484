import { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Card, CardBody, CardFooter, ButtonGroup, Input, ListGroup, ListGroupItem, Alert, Form, FormGroup, Label, FormFeedback, CardHeader } from 'reactstrap';
import './App.css';
import { useDispatch, useSelector } from "react-redux";
import Utils from './Utils';
import { Outlet, Link, useSearchParams, useParams, NavLink as RNavLink, useNavigate } from 'react-router-dom';
import Paginator from './Components/Paginator';
import { current } from '@reduxjs/toolkit';
import { selectUser, selectLoggedIn } from "./store/slices/authSlice";
import Dropzone from 'react-dropzone'
const dayjs = require('dayjs')
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export default function Expenses() {

    return (
        <Outlet />
    )
}

export function List() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [expenses, setExpenses] = useState([]);
    const [pages, setPages] = useState(1)
  
    useEffect(() => {
      Utils.api('expenses', {
        method: 'GET',
        params: { page: searchParams.get("page") || 1 }
      }).then((response) => {
        setExpenses(response.data);
        setPages(response.last_page);
      });
    }, [searchParams]);
  
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2>Gastos</h2>
          <Button tag={RNavLink} to='/expenses/create' color='primary'>Crear</Button>
        </div>
        <Table bordered>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Tipo</th>
              <th>Categoría</th>
              <th>Usuario</th>
              <th>Total</th>
            </tr>
          </thead>
          {expenses.map(e => <tr>
            <td><Link to={'/expenses/' + e.id}>{e.date}</Link></td>
            <td><Link to={'/expenses/' + e.id}>{e.type === 'cogs' ? 'Costo de Ventas' : 'SG&A'}</Link></td>
            <td><Link to={'/expenses/' + e.id}>{e.category}</Link></td>
            <td><Link to={'/expenses/' + e.id}>{e.user.name}</Link></td>
            <td><Link to={'/expenses/' + e.id}>{e.total}</Link></td>
          </tr>)}
        </Table>
        <Paginator baseUrl={`/expenses`} pages={pages} current={searchParams.get("page") || 1} />
      </div>
    )
  }

  export function Create() {

    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [type, setType] = useState("");
    const [category, setCategory] = useState("");
    const [user, setUser] = useState({});
    const [date, setDate] = useState(new Date().toISOString().slice(0,10));
    const [project, setProject] = useState({});
    const [concept, setConcept] = useState("");
    const [total, setTotal] = useState("");
    const [error, setError] = useState({});
    const [reimbursable, setReimbursable] = useState(false);
    const [categories, setCategories] = useState({
      cogs: [
        'Sueldos y Salarios',
        'Equipo y Materiales',
        'Otros',
      ],
      opex: [
        'Renta',
        'Equipo y Mantenimiento',
        'Facilities',
        'Comisiones de Venta',
        'Sueldos y Salarios',
        'Viáticos y Representación',
        'Otros',
      ]
    })
                
    const navigate = useNavigate();
    

    useEffect(() => {

    
      Utils.api('users', {
        
      }).then(response => {
        setUsers(response);
      });
      

      Utils.api('projects', {

      }).then(response => {
        setProjects(response);
      })
    }, []);

    const saveExpense = e => {
      e.preventDefault();
      Utils.api('expenses', {
        method: 'POST',
        body: {
          date: date,
          user_id: user.id,
          project_id: project?.id,
          type: type,
          category: category,
          concept: concept,
          total: total,
          is_reimbursable: reimbursable
        }
      }).then(response => {
        navigate('/expenses');
      }).catch(error => {
        setError(error.response.data);
      });
    }

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2>Registrar gasto</h2>
          <Button tag={RNavLink} to='/expenses' color='link'>Regresar a la lista</Button>
        </div>
        { error?.message ? <Alert color='danger'>{ error.message }</Alert> : null }
        <Form onSubmit={ saveExpense }>
          <Row>
            <Col></Col>
            <Col md={6}>
              <FormGroup>
                <Label for="date">
                  Fecha
                </Label>
                <Input
                  id="date"
                  name="date"
                  placeholder="Selecciona la fecha del gasto"
                  type="date"
                  value={ date }
                  onChange={ e => setDate(e.target.value) }
                  invalid={ error.errors?.date }
                />
                { error?.errors?.date ? <FormFeedback>{ error?.errors?.date[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
            
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="type">
                  Tipo de Gasto
                </Label>
                <Input
                  id="type"
                  name="type"
                  type="select"
                  value={ type }
                  onChange={ e => setType(e.target.value) }
                  invalid={ error.errors?.type }
                >
                  <option value=''>Selecciona un tipo de gasto</option>
                  <option value='cogs'>Costo de Ventas</option>
                  <option value='opex'>SG&A</option>
                </Input>
                { error?.errors?.type ? <FormFeedback>{ error?.errors?.type[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="type">
                  Categoría
                </Label>
                <Input
                  id="type"
                  name="type"
                  type="select"
                  value={ category }
                  onChange={ e => setCategory(e.target.value) }
                  invalid={ error.errors?.category }
                >
                  <option value=''>Selecciona una categoría</option>
                  { type ? categories[type].map(c => <option value={ c }>{ c }</option>) : null }
                </Input>
                { error?.errors?.category ? <FormFeedback>{ error?.errors?.category[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="user_id">
                  Usuario
                </Label>
                <Input
                  id="user_id"
                  name="user_id"
                  type="select"
                  value={ user?.id }
                  onChange={ e => setUser(users.find(u => u.id == e.target.value)) }
                  invalid={ error.errors?.user_id }
                >
                  <option value=''>Selecciona un usuario</option>
                  { users.map(u => <option value={ u.id }>{ u.name }</option>) }
                </Input>
                { error?.errors?.user_id ? <FormFeedback>{ error?.errors?.user_id[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="project_id">
                  Proyecto
                </Label>
                <Input
                  id="project_id"
                  name="project_id"
                  type="select"
                  value={ project?.id }
                  onChange={ e => setProject(projects.find(p => p.id == e.target.value)) }
                  invalid={ error.errors?.project_id }
                >
                  <option value=''>Selecciona un proyecto (si aplica)</option>
                  { projects.map(p => <option value={ p.id }>{ p.name }</option>) }
                </Input>
                { error?.errors?.project_id ? <FormFeedback>{ error?.errors?.project_id[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="concept">
                  Concepto
                </Label>
                <Input
                  id="concept"
                  name="concept"
                  type="textarea"
                  onChange={ e => setConcept(e.target.value) }
                  invalid={ error.errors?.concept }
                  value={concept}
                ></Input>
                { error?.errors?.concept ? <FormFeedback>{ error?.errors?.concept[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col md={6}>
              <FormGroup>
                <Label for="total">
                  Total
                </Label>
                <Input
                  id="total"
                  name="total"
                  placeholder="Escribe el monto total gastado"
                  type="number"
                  value={ total }
                  onChange={ e => setTotal(e.target.value) }
                  invalid={ error.errors?.total }
                />
                { error?.errors?.total ? <FormFeedback>{ error?.errors?.total[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup switch>
                <Input
                  type="switch"
                  checked={reimbursable}
                  id="reimbursable"
                  onClick={() => {
                    setReimbursable(!reimbursable);
                  }}
                />
                <Label check for="reimbursable">Marcar como gasto por reembolsar</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex justify-content-end'>
              <Button color='primary'>Guardar</Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }

  export function Detail() {

    const [expense, setExpense] = useState({});
    let { expenseId } = useParams();
    const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        let errors = this.state.errors;
        errors.push(['It is only possible to upload XML or PDF files']);
        this.setState({
          errors: errors
        });
      }
      var formData = new FormData();
      for (var x = 0; x < acceptedFiles.length; x++) {
        formData.append(`files_${x}`, acceptedFiles[x]);
      }
  
      Utils.api(`expenses/${expenseId}/files`, {
        method: 'POST', // or 'PUT'
        body: formData,
      })
        .catch(error => {
          console.error('Error:', error);
        })
        .then(response => {
          // this.setState({ redirect: true });
          if(response && response.expense) {
            setExpense(response.expense)
          }
        });
    }, []);

    const deleteFile = () => {
      Utils.api(`expenses/${expenseId}/files/${selectedFile.id}`, {
        method: 'DELETE',
      }).then(function (response) {
        setIsDeleteFileModalOpen(false)
        if(response && response.expense) {
          setExpense(response.expense)
        }
      });
    }
    
    useEffect(() => {
      Utils.api(`expenses/${expenseId}`, {
        method: 'GET'
      }).then((response) => {
        setExpense(response);
      });
      
    }, []);

    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <h2>Detalle de gasto</h2>
          <div>
            <Button tag={RNavLink} to='/expenses' color='link'>Regresar a la lista</Button>
            <Button tag={RNavLink} to={`/expenses/${expenseId}/edit`} color='warning'>Modificar</Button>
          </div>
        </div>
        <div className="mt-4">
          <Col md={9}>
              <Row>
                <Col>
                  <label className='text-muted small'>Fecha</label>
                  <p>{ expense.date }</p>
                </Col>
                <Col>
                  <label className='text-muted small'>Usuario</label>
                  <p>{ expense.user?.name }</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className='text-muted small'>Tipo</label>
                  <p>{ expense.type }</p>
                </Col>
                <Col>
                  <label className='text-muted small'>Categoría</label>
                  <p>{ expense.category }</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className='text-muted small'>Proyecto</label>
                  { expense.project ? <p>{ expense.project.name }</p> : <p className='text-muted'>Ningún proyecto asociado</p> }
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className='text-muted small'>Concepto</label>
                  <p>{ expense.concept }</p>
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col>
                  <label className='text-muted small'>Total</label>
                  <h3>{ expense.total }</h3>
                </Col>
              </Row>
              <h4 className='mt-3'>Archivos relacionados</h4>
              <Dropzone onDrop={ onDrop }>
                {({getRootProps, getInputProps}) => (
                  <section className='border p-4 mt-4'>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className='m-0 text-muted text-center'>Arrastra y suelta archivos aquí para adjuntarlos a este gasto.</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              { expense.files ?
              <ListGroup className='mt-4'>
                { expense.files.map(f => 
                <ListGroupItem className='d-flex justify-content-between'>
                  <span>
                    {f.original_filename}
                    <br/>
                    <small className="text-muted">Cargado por {f.user.name} el { dayjs(f.created_at).format('d MMM YYYY') }</small>
                  </span>
                  <div>
                    <Button color="primary" size="sm" className="mr-2" onClick={() => {
                      Utils.blob(`expenses/${expenseId}/files/${f.id}`)
                        .then(blob => {
                          var url = window.URL.createObjectURL(blob);
                          var a = document.createElement('a');
                          a.href = url;
                          a.download = f.original_filename;
                          document.body.appendChild(a);
                          a.click();
                          a.remove();
                        });
                    }}>Descargar</Button>&nbsp;
                    <Button type='button' color='danger' size='sm' onClick={ () => { setIsDeleteFileModalOpen(true); setSelectedFile(f) } }>Eliminar</Button>
                  </div>
                </ListGroupItem>)}
              </ListGroup>
              : <></> }
            </Col>
        </div>

        
        <Modal isOpen={ isDeleteFileModalOpen } toggle={() => { setIsDeleteFileModalOpen(false); setSelectedFile(null); }}>
          <ModalHeader toggle={() => { setIsDeleteFileModalOpen(false); setSelectedFile(null); }}>Detalle de registro</ModalHeader>
          <ModalBody>
              <p>Una vez eliminado, este archivo no podrá ser recuperado. ¿Deseas eliminar el archivo asociado al gasto?</p>
          </ModalBody>
          <ModalFooter className='justify-content-between'>
              <Button color="danger" onClick={ () => { deleteFile(); } }>
                Si, eliminar el archivo
              </Button>
              <Button color="secondary" onClick={() => { setIsDeleteFileModalOpen(false); setSelectedFile(null); } }>
                No, regresar sin eliminar
              </Button>
          </ModalFooter>
        </Modal>
        
        
      </>
    )
  }
  
  export function Edit() {

    let { expenseId } = useParams();

    const [isDeleteExpenseModalOpen, setIsDeleteExpenseModalOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [type, setType] = useState("");
    const [category, setCategory] = useState("");
    const [user, setUser] = useState({});
    const [date, setDate] = useState(new Date().toISOString().slice(0,10));
    const [project, setProject] = useState({});
    const [concept, setConcept] = useState("");
    const [total, setTotal] = useState("");
    const [error, setError] = useState({});
    const [reimbursable, setReimbursable] = useState(false);
    const [categories, setCategories] = useState({
      cogs: [
        'Sueldos y Salarios',
        'Equipo y Materiales',
        'Otros',
      ],
      opex: [
        'Renta',
        'Equipo y Mantenimiento',
        'Facilities',
        'Comisiones de Venta',
        'Sueldos y Salarios',
        'Viáticos y Representación',
        'Otros',
      ]
    })
                
    const navigate = useNavigate();
    
    useEffect(() => {
      Utils.api(`expenses/${expenseId}`, {
        method: 'GET'
      }).then(response => {
        setDate(response?.date)
        setType(response?.type)
        setCategory(response?.category)
        setProject(response?.project)
        setUser(response?.user)
        setConcept(response?.concept)
        setTotal(response?.total)
        setReimbursable(response?.is_reimbursable)
      });
    
      Utils.api('users', {
        
      }).then(response => {
        setUsers(response);
      });
      

      Utils.api('projects', {

      }).then(response => {
        setProjects(response);
      })
    }, []);

    const saveExpense = e => {
      e.preventDefault();
      Utils.api(`expenses/${expenseId}`, {
        method: 'PUT',
        body: {
          date: date,
          user_id: user.id,
          project_id: project?.id,
          type: type,
          category: category,
          concept: concept,
          total: total,
          is_reimbursable: reimbursable
        }
      }).then(response => {
        navigate(`/expenses/${expenseId}`);
      }).catch(error => {
        setError(error.response.data);
      });
    }

    const deleteExpense = () => {
      Utils.api(`expenses/${expenseId}`, {
        method: 'DELETE',
      }).then(function (response) {
        setIsDeleteExpenseModalOpen(false)
        navigate(`/expenses`);
      });
    }

    return (
      <>
        <div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2>Editar gasto</h2>
            <div>
              <Button tag={RNavLink} to={`/expenses/${expenseId}`} color='link'>Regresar al detalle</Button>
              <Button type='button' color='danger' onClick={ () => setIsDeleteExpenseModalOpen(true) }>Eliminar</Button>
            </div>
          </div>
          { error?.message ? <Alert color='danger'>{ error.message }</Alert> : null }
          <Form onSubmit={ saveExpense }>
            <Row>
              <Col></Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="date">
                    Fecha
                  </Label>
                  <Input
                    id="date"
                    name="date"
                    placeholder="Selecciona la fecha del gasto"
                    type="date"
                    value={ date }
                    onChange={ e => setDate(e.target.value) }
                    invalid={ error.errors?.date }
                  />
                  { error?.errors?.date ? <FormFeedback>{ error?.errors?.date[0] }</FormFeedback> : null }
                </FormGroup>
              </Col>
              
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="type">
                    Tipo de Gasto
                  </Label>
                  <Input
                    id="type"
                    name="type"
                    type="select"
                    value={ type }
                    onChange={ e => setType(e.target.value) }
                    invalid={ error.errors?.type }
                  >
                    <option value=''>Selecciona un tipo de gasto</option>
                    <option value='cogs'>Costo de Ventas</option>
                    <option value='opex'>SG&A</option>
                  </Input>
                  { error?.errors?.type ? <FormFeedback>{ error?.errors?.type[0] }</FormFeedback> : null }
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="type">
                    Categoría
                  </Label>
                  <Input
                    id="type"
                    name="type"
                    type="select"
                    value={ category }
                    onChange={ e => setCategory(e.target.value) }
                    invalid={ error.errors?.category }
                  >
                    <option value=''>Selecciona una categoría</option>
                    { type ? categories[type].map(c => <option value={ c }>{ c }</option>) : null }
                  </Input>
                  { error?.errors?.category ? <FormFeedback>{ error?.errors?.category[0] }</FormFeedback> : null }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="user_id">
                    Usuario
                  </Label>
                  <Input
                    id="user_id"
                    name="user_id"
                    type="select"
                    value={ user?.id }
                    onChange={ e => setUser(users.find(u => u.id == e.target.value)) }
                    invalid={ error.errors?.user_id }
                  >
                    <option value=''>Selecciona un usuario</option>
                    { users.map(u => <option value={ u.id }>{ u.name }</option>) }
                  </Input>
                  { error?.errors?.user_id ? <FormFeedback>{ error?.errors?.user_id[0] }</FormFeedback> : null }
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="project_id">
                    Proyecto
                  </Label>
                  <Input
                    id="project_id"
                    name="project_id"
                    type="select"
                    value={ project?.id }
                    onChange={ e => setProject(projects.find(p => p.id == e.target.value)) }
                    invalid={ error.errors?.project_id }
                  >
                    <option value=''>Selecciona un proyecto (si aplica)</option>
                    { projects.map(p => <option value={ p.id }>{ p.name }</option>) }
                  </Input>
                  { error?.errors?.project_id ? <FormFeedback>{ error?.errors?.project_id[0] }</FormFeedback> : null }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="concept">
                    Concepto
                  </Label>
                  <Input
                    id="concept"
                    name="concept"
                    type="textarea"
                    onChange={ e => setConcept(e.target.value) }
                    invalid={ error.errors?.concept }
                    value={ concept }
                  ></Input>
                  { error?.errors?.concept ? <FormFeedback>{ error?.errors?.concept[0] }</FormFeedback> : null }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="total">
                    Total
                  </Label>
                  <Input
                    id="total"
                    name="total"
                    placeholder="Escribe el monto total gastado"
                    type="number"
                    value={ total }
                    onChange={ e => setTotal(e.target.value) }
                    invalid={ error.errors?.total }
                  />
                  { error?.errors?.total ? <FormFeedback>{ error?.errors?.total[0] }</FormFeedback> : null }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup switch>
                  <Input
                    type="switch"
                    checked={reimbursable}
                    id="reimbursable"
                    onClick={() => {
                      setReimbursable(!reimbursable);
                    }}
                  />
                  <Label check for="reimbursable">Marcar como gasto por reembolsar</Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-end'>
                <Button color='primary'>Guardar</Button>
              </Col>
            </Row>
          </Form>
        </div>
        <Modal isOpen={ isDeleteExpenseModalOpen } toggle={() => { setIsDeleteExpenseModalOpen(false); }}>
          <ModalHeader toggle={() => { setIsDeleteExpenseModalOpen(false); }}>Detalle de registro</ModalHeader>
          <ModalBody>
              <p>Una vez eliminado, este gasto no podrá ser recuperado. ¿Deseas eliminar el gasto y sus archivos?</p>
          </ModalBody>
          <ModalFooter className='justify-content-between'>
              <Button color="danger" onClick={ deleteExpense } >
                Si, eliminar el gasto
              </Button>
              <Button color="secondary" onClick={() => { setIsDeleteExpenseModalOpen(false); } }>
                No, regresar sin eliminar
              </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }