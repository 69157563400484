import { useState, useEffect } from 'react';
import {
  useNavigate,
  Link
} from "react-router-dom";

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

export default function Paginator(props) {
    const navigate = useNavigate();
    const [ range, setRange ] = useState([]);
    const [ ep, setEp ] = useState(0);

    const arrayRange = (start, stop) => {
        var r = [];
        for(var i=start;i<=stop;i++) {
            r.push(i);
        }
        return r;
    };

    useEffect(() => {
        const page = parseInt(props.current);
        var startPage = ((page-2) < 1 ? 1 : (page-2));
        const endPage = ((startPage + 4) > parseInt(props.pages) ? parseInt(props.pages) : (startPage + 4));
        if ((endPage - 5) > 1) {
            startPage = endPage - 4;
        }
        setRange(arrayRange(startPage, endPage));
    }, [props]);

    return (

        <Pagination>
            <PaginationItem disabled={ parseInt(props.current) === 1 }>
                <PaginationLink
                first
                tag={Link}
                to={`${props.baseUrl}?page=1`}
                />
            </PaginationItem>
            <PaginationItem disabled={ parseInt(props.current) === 1 }>
                <PaginationLink
                tag={ Link }
                to={`${props.baseUrl}?page=${parseInt(props.current) - 1}`}
                previous
                />
            </PaginationItem>
            { range.map(page => 
                <PaginationItem active={ parseInt(page) == parseInt(props.current) }>
                    <PaginationLink tag={ Link } to={`${props.baseUrl}?page=${page}`}>
                    { page }
                    </PaginationLink>
                </PaginationItem>
            ) }
            <PaginationItem disabled={ parseInt(props.current) === parseInt(props.pages) } >
                <PaginationLink
                tag={ Link }
                to={`${props.baseUrl}?page=${parseInt(props.current) + 1}`}
                next
                />
            </PaginationItem>
            <PaginationItem disabled={ parseInt(props.current) === parseInt(props.pages) }>
                <PaginationLink
                tag={ Link }
                to={`${props.baseUrl}?page=${props.pages}`}
                last
                />
            </PaginationItem>
            
        </Pagination>
    )
}