import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  NavLink as RNavLink,
  Outlet,
  useNavigate,
  Navigate
} from "react-router-dom";
import { Alert, FormFeedback, Container, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, NavbarText, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Form, Row, Col, FormGroup, Label, Input, ModalFooter, Button } from 'reactstrap';
import Timelogs from './Timelogs';
import Projects, { List as ProjectList, Create as ProjectCreate, Detail as ProjectDetail } from './Projects';
import Expenses, { List as ExpenseList, Create as ExpenseCreate, Detail as ExpenseDetail, Edit as ExpenseEdit } from './Expenses';
import Users, { List as UserList, Create as UserCreate, Detail as UserDetail, Edit as UserEdit } from './Users';
import { Effort as EffortReport } from './Reports';
import Login from './Login';
import { logout } from './store/slices/authSlice';
import { selectUser, selectLoggedIn } from "./store/slices/authSlice";
import Utils from './Utils';
import './App.css';

function App() {

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/login" Component={Login} />
          <Route element={<Layout />}>
            <Route exact path="/" Component={Timelogs} />
            <Route exact path="/projects" Component={Projects}>
              <Route exact path="" Component={ProjectList} />
              <Route path="create" Component={ProjectCreate} />
              <Route path=":projectId/*" Component={ProjectDetail} />
            </Route>
            <Route exact path="/expenses" Component={Expenses}>
              <Route exact path="" Component={ExpenseList} />
              <Route path="create" Component={ExpenseCreate} />
              <Route exact path=":expenseId" Component={ExpenseDetail} />
              <Route exact path=":expenseId/edit" Component={ExpenseEdit} />
            </Route>
            <Route exact path="/users" Component={Users}>
              <Route exact path="" Component={UserList} />
              <Route path="create" Component={UserCreate} />
              <Route path=":userId/*" Component={UserDetail} />
              <Route path=":userId/edit" Component={UserEdit} />
            </Route>
            <Route exact path="/reports/effort" Component={EffortReport}/>
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

function Layout() {
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const loggedIn = useSelector(selectLoggedIn);
  const handleLogout = async () => {
    await dispatch(logout());
    return navigate('/login');
  }
  
  if(!loggedIn) {
    return <Navigate replace to="/login" />;
  }
  
  return (
    <>
      <Navbar color="light" container light expand="md">
          <NavbarBrand href="/">cabinet</NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="me-auto" navbar>
              <NavItem>
                <NavLink tag={RNavLink} to='/'>Tiempo</NavLink>
              </NavItem>
              { user.role === 'manager' ? 
              <>
                <NavItem>
                  <NavLink tag={RNavLink} to="/projects">Proyectos</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RNavLink} to="/expenses">Gastos</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RNavLink} to="/users">Usuarios</NavLink>
                </NavItem>
                <NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Reportes
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem tag={RNavLink} to="/reports/effort">
                        Reporte de Esfuerzo
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavItem>
              </>
              : null }
            </Nav>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {user?.name}
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem onClick={ () => setIsUpdatePasswordModalOpen(true) }>
                    Cambiar contraseña
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={ handleLogout }>
                    Cerrar Sesión
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
          
        </Navbar>
      <Container className='my-3'>
        <Outlet />
        <UpdatePasswordModal isOpen={ isUpdatePasswordModalOpen } toggle={ () => setIsUpdatePasswordModalOpen(!isUpdatePasswordModalOpen) } />
      </Container>
    </>
  )
}

function UpdatePasswordModal(props) {

  const user = useSelector(selectUser);
  const [error, setError] = useState({});

  const handleUpdatePassword = () => {
    Utils.api(`users/${user.id}`, {
      method: 'PUT',
      body: {
        password: password,
        new_password: newPassword,
        new_password_confirmation: newPasswordConfirmation
      }
    }).then((response) => {
      setPassword("");
      setNewPassword("");
      setNewPasswordConfirmation("");
      setError({});
      props.toggle();
    }).catch((error) => {
      setError(error.response.data);
    });
  }

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

  return (
    <Modal isOpen={ props.isOpen } toggle={ props.toggle }>
      <ModalHeader toggle={ props.toggle }>Cambiar contraseña</ModalHeader>
      <ModalBody>
        { error?.message ? <Alert color='danger'>{ error.message }</Alert> : null }
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="examplePassword">
                  Contraseña actual
                </Label>
                <Input
                  placeholder="Escribe tu contraseña actual"
                  type="password"
                  value={ password }
                  onChange={ e => setPassword(e.target.value) }
                  invalid={ error?.errors?.password }
                />
                { error?.errors?.password ? <FormFeedback>{ error?.errors?.password[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="examplePassword">
                  Nueva contraseña
                </Label>
                <Input
                  placeholder="Escribe tu nueva contraseña"
                  type="password"
                  value={ newPassword }
                  onChange={ e => setNewPassword(e.target.value) }
                  invalid={ error?.errors?.new_password }
                />
                { error?.errors?.new_password ? <FormFeedback>{ error?.errors?.new_password[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="examplePassword">
                  Confirmar contraseña
                </Label>
                <Input
                  placeholder="Vuelve a escribir tu nueva contraseña"
                  type="password"
                  value={ newPasswordConfirmation }
                  onChange={ e => setNewPasswordConfirmation(e.target.value) }
                  invalid={ error?.errors?.new_password_confirmation }
                />
                { error?.errors?.new_password_confirmation ? <FormFeedback>{ error?.errors?.new_password_confirmation[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
          </Row>
          
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ handleUpdatePassword }>
          Cambiar contraseña
        </Button>{' '}
        <Button color="secondary" onClick={ props.toggle }>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default App;
