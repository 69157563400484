import axios from 'axios';
import store from './store';
import {
  setCredentials,
  logout
} from "./store/slices/authSlice";

/**
 * NOTE: Since we are implementing RTK Query we need to remove this funcion, all the API call are goint to be using RTK
 */

const api_base = process.env.REACT_APP_API_URL;
// const api_base = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = api_base;

const { token, loggedIn, refreshToken } = store.getState().auth || {};
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

// Interceptor setup
axios.interceptors.response.use((response) => {
  return response
},
  function (error) {
    const originalRequest = error.config;
    
    if (error.response && error.response.status === 401 && !originalRequest._retry && typeof token !== 'undefined' && error.config.url !== 'auth/login') {
      
      originalRequest._retry = true;

      return axios.post('users/refresh', { headers: { Authorization: `Bearer ${refreshToken}` } })
        .then(async res => {
          if (res.status === 200) {
            // 1) put token to LocalStorage
            await store.dispatch(setCredentials(res.data));

            // 2.5) Update on originalRequest
            originalRequest.headers['Authorization'] = `Bearer ${res.data.token}`;

            // 3) return originalRequest object with Axios.
            return axios(originalRequest);
          }
        })
        .catch(error => {
          store.dispatch(logout())
          return Promise.reject(error);
        })
    }

    // return Error object with Promise
    return Promise.reject(error);
  });

class Utils {

  static api(endpoint, options = {}) {
    // if(store.getState().loggedIn){
    //   options.headers = options.headers || {};
    //   options.headers.Authorization = `Bearer ${ token }`;
    // }
    // fetch(api_bas+endpoint, options);
    if (options.method === 'POST') {
      return axios.post(endpoint, options.body, options).then(r => r.data);
    } else if (options.method === 'PUT') {
      return axios.put(endpoint, options.body, options).then(r => r.data);
    } else if (options.method === 'DELETE') {
      return axios.delete(endpoint, options).then(r => r.data);
    } else if (options.method === 'GET' || options.method === undefined) {
      return axios.get(endpoint, options).then(r => r.data);
    }

    return new Promise(function (resolve, reject) {
      fetch(api_base + endpoint, options).then(r => {
        if (r.status === 401) {
          store.dispatch(logout())
        }
        resolve(r.json());
      }).catch(function (err) {

      });
    });

  }

  static blob(endpoint, options = {}) {
    if (loggedIn) {
      options.headers = options.headers || {};
      options.headers.Authorization = `Bearer ${token}`;
    }

    return new Promise(function (resolve, reject) {
      fetch(api_base + endpoint, options).then(r => {
        resolve(r.blob());
      }).catch(function (err) {

      });
    });
  }

  static setBearerToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  static CancelToken() {
    return axios.CancelToken.source();
  }

}

export default Utils;
