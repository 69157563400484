import { Alert, ButtonToolbar, ButtonGroup, Button, Badge, Modal, ModalBody, ModalFooter, ModalHeader, Form, Row, Col, Input, FormGroup, Label, ListGroup, ListGroupItem, FormFeedback } from 'reactstrap';
import { useState, useEffect } from 'react';
import './App.css';
import Utils from './Utils';
const dayjs = require('dayjs')
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export default function Timelogs() {

    const [week, setWeek] = useState(dayjs());
    const [date, setDate] = useState(undefined);
    const [isCreateTimelogModalOpen, setIsCreateTimelogModalOpen] = useState(false);
    const [isTimelogDetailModalOpen, setIsTimelogDetailModalOpen] = useState(false);
    const [isDeleteTimelogModalOpen, setIsDeleteTimelogModalOpen] = useState(false);
    const [timelogs, setTimelogs] = useState([]);
    const [time, setTime] = useState("");
    const [description, setDescription] = useState("");
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState();
    const [error, setError] = useState({});
    const [selectedTimelog, setSelectedTimelog] = useState(null);

    useEffect(() => {
      if (selectedTimelog !== null) {
        setIsTimelogDetailModalOpen(true);
      }
    }, [selectedTimelog])

    useEffect(() => {
      if(!isDeleteTimelogModalOpen) {
        setSelectedTimelog(null);
      }
    }, [isDeleteTimelogModalOpen]);

    useEffect(() => {
      Utils.api('timelogs', {
        method: 'GET',
        params: { from: week.day(0).format('YYYY-MM-DD') }
      }).then((response) => {
        setTimelogs(response);
      });
    }, [week])

    useEffect(() => {
        if(date) {
            setIsCreateTimelogModalOpen(true);
            Utils.api(`projects`, {
              method: 'GET'
            })
                .then(function (response) {
                    setProjects(response);
                });
        }
    }, [date])

    const deleteTimelog = () => {
      Utils.api(`timelogs/${selectedTimelog.id}`, {
        method: 'DELETE',
      }).then(function (response) {
        let ts = timelogs;
        ts[selectedTimelog.date] = ts[selectedTimelog.date].filter(t => t.id != selectedTimelog.id);
        if(ts[selectedTimelog.date].length === 0) {
          delete ts[selectedTimelog.date];
        }
        setTimelogs(ts);
        setIsDeleteTimelogModalOpen(false)
      });
    }
      
    const saveTimelog = e => {
      Utils.api('timelogs', {
        method: 'POST',
        body: {
          date: date.format('YYYY-MM-DD'),
          project_id: project?.id,
          time: time,
          description: description
        }
      }).then(function (response) {
        setProject();
        setTime("");
        setDescription("");
        setIsCreateTimelogModalOpen(false);
        var nt = timelogs;
        if(nt[date.format('YYYY-MM-DD')]) {
            nt[date.format('YYYY-MM-DD')].push(response);
        }
        else {
            nt[date.format('YYYY-MM-DD')] = [response];
        }
        setTimelogs(nt)
      }).catch(error => {
        setError(error.response.data);
      });
    };

    return (
        <>
        <div className='d-flex justify-content-between mb-3'>
            <Button outline onClick={ () => setWeek(dayjs()) }>Ir a hoy</Button>
            <ButtonToolbar>
                <ButtonGroup>
                    <Button color='primary' outline onClick={ () => setWeek(week.subtract(1 ,'weeks')) }>Atrás</Button>
                    <Button color='primary' outline onClick={ () => setWeek(week.add(1 ,'weeks')) }>Adelante</Button>    
                </ButtonGroup>
            </ButtonToolbar>
            
        </div>
        
        <ButtonToolbar className='mb-3'>
          <ButtonGroup className='w-100'>
          {[...Array(7)].map(
            (_, i) => 
            <Button className='w-100' color="primary" outline={ !week.day(0).add(i, 'days').isSame(dayjs(), 'day') } onClick={ () => setDate(week.day(0).add(i, 'days')) }>
              { week.day(0).add(i, 'days').format('dddd') }<br/>
              { week.day(0).add(i, 'days').format('MMM D') }
            </Button>
          )}
          </ButtonGroup>
        </ButtonToolbar>
        <div>
          <ListGroup horizontal>
            { Object.values(timelogs).length === 0 ? <ListGroupItem className='w-100 text-center'><span className='text-muted'>No existen registros</span></ListGroupItem> : [...Array(7)].map(
              (_, i) => <ListGroupItem
              className='w-100 p-0'
            >
              { timelogs[week.day(0).add(i, 'days').format('YYYY-MM-DD')]?.map(t => 
              <div role='button' className='border-bottom p-2' onClick={ () => { setSelectedTimelog(t);  } }>
                <Badge>{t.project.name}</Badge>
                <div>{t.description}</div>
                <div className='d-flex justify-content-between'>
                  <small>{ dayjs.duration(t.time, 'minutes').format('H:mm') }</small>
                  { t.approved_at ? <Badge pill color='success'>✓</Badge> : null }
                </div>
              </div>) }
            </ListGroupItem>
            )}
          </ListGroup>
        </div>

        <Modal isOpen={ isCreateTimelogModalOpen } toggle={() => setIsCreateTimelogModalOpen(false)}>
          <ModalHeader toggle={() => setIsCreateTimelogModalOpen(false)}>Registrar tiempo</ModalHeader>
          <ModalBody>
            { error?.message ? <Alert color='danger'>{ error.message }</Alert> : null }
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="project">
                      Proyecto
                    </Label>
                    <Input
                      type="select"
                      value={ project?.id }
                      invalid={ error?.errors?.project_id }
                      onChange={ e => setProject(projects.find(p => p.id == e.target.value)) }>
                        <option value="">Selecciona un proyecto</option>
                        { projects.map(p => <option value={ p.id }>{ p.name }</option>) }
                    </Input>
                    { error?.errors?.project_id ? <FormFeedback>{ error?.errors?.project_id[0] }</FormFeedback> : null }
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="time">
                      Tiempo
                    </Label>
                    <Input
                      placeholder="0:00"
                      type="text"
                      value={ time }
                      invalid={ error?.errors?.time }
                      onChange={ e => setTime(e.target.value) }
                    />
                    { error?.errors?.time ? <FormFeedback>{ error?.errors?.time[0] }</FormFeedback> : null }
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="description">
                      Descripción
                    </Label>
                    <Input
                      value={ description }
                      invalid={ error?.errors?.description }
                      onChange={ e => setDescription(e.target.value) }
                      type="textarea"></Input>
                    { error?.errors?.description ? <FormFeedback>{ error?.errors?.description[0] }</FormFeedback> : null }
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={saveTimelog }>
              Registrar
            </Button>{' '}
            <Button color="secondary" onClick={() => setIsCreateTimelogModalOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={ isTimelogDetailModalOpen } toggle={() => { setIsTimelogDetailModalOpen(false); setSelectedTimelog(null); }}>
          <ModalHeader toggle={() => { setIsTimelogDetailModalOpen(false); setSelectedTimelog(null); }}>Detalle de registro</ModalHeader>
          <ModalBody>
              <Row>
                <Col>
                  <label className='text-muted small'>Proyecto</label>
                  <p>{ selectedTimelog?.project?.name }</p>
                </Col>
                <Col>
                  <label className='text-muted small'>Tiempo</label>
                  <p>{ dayjs.duration(selectedTimelog?.time, 'minutes').format('H:mm') }</p>
                </Col>
              </Row>
              <Row>
              <Col>
                  <label className='text-muted small'>Fecha</label>
                  <p>{ dayjs(selectedTimelog?.date).format('d MMMM YYYY') }</p>
                </Col>
                <Col>
                  <label className='text-muted small'>Estado</label>
                  <p>{ selectedTimelog?.approved_at ? `Aprobado` : `Por aprobar` }</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className='text-muted small'>Registrado en</label>
                  <p>{ dayjs(selectedTimelog?.created_at).format('d MMMM YYYY') }</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className='text-muted small'>Descripción</label>
                  <p>{ selectedTimelog?.description }</p>
                </Col>
              </Row>
          </ModalBody>
          <ModalFooter className='justify-content-between'>
              <Button color="danger" onClick={ () => { setIsTimelogDetailModalOpen(false); setIsDeleteTimelogModalOpen(true); } }>
                Eliminar
              </Button>
              <Button color="secondary" onClick={() => { setIsTimelogDetailModalOpen(false); setSelectedTimelog(null); } }>
                Regresar
              </Button>
          </ModalFooter>
        </Modal>
        
        <Modal isOpen={ isDeleteTimelogModalOpen } toggle={() => setIsDeleteTimelogModalOpen(false)}>
          <ModalHeader toggle={() => setIsDeleteTimelogModalOpen(false)}>Eliminar registro</ModalHeader>
          <ModalBody>
              <p>Al eliminar éste registro no es posible recuperarlo, y si está aprobado necesitarás enviar de nuevo el tiempo para su aprobación. ¿Deseas eliminar éste registro?</p>
          </ModalBody>
          <ModalFooter className='justify-content-between'>
              <Button color="danger" onClick={ () => deleteTimelog() }>
                Si, eliminar el registro
              </Button>
              <Button color="secondary" onClick={() => setIsDeleteTimelogModalOpen(false) }>
                No, regresar sin eliminar
              </Button>
          </ModalFooter>
        </Modal>
      </>
    )
}