import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authSlice from "./slices/authSlice";

const store = configureStore({
    reducer: {
        auth: authSlice,
    }
});

setupListeners(store.dispatch);

export default store;