import { useState, useEffect } from 'react';
import { Table, Nav, NavItem, NavLink, Button, Row, Col, Card, CardBody, CardFooter, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input, ListGroup, ListGroupItem, Alert, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import './App.css';
import Utils from './Utils';
import { Outlet, Link, useSearchParams, useParams, NavLink as RNavLink, useNavigate, Routes, Route } from 'react-router-dom';
import Paginator from './Components/Paginator';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
const dayjs = require('dayjs')
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Users() {

    return (
        <Outlet />
    )
}

export function List() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [users, setUsers] = useState([]);
    const [pages, setPages] = useState(1)
  
    useEffect(() => {
      Utils.api('users', {
        method: 'GET',
        params: { page: searchParams.get("page") || 1 }
      }).then((response) => {
        setUsers(response.data);
        setPages(response.last_page);
      });
    }, [searchParams]);
  
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2>Usuarios</h2>
          <Button tag={RNavLink} to='/users/create' color='primary'>Crear</Button>
        </div>
        <Table bordered>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Correo Electrónico</th>
              <th>Rol</th>
            </tr>
          </thead>
          {users.map(u => <tr>
            <td><Link to={'/users/' + u.id}>{u.name}</Link></td>
            <td><Link to={'/users/' + u.id}>{u.email}</Link></td>
            <td><Link to={'/users/' + u.id}>{u.role}</Link></td>
          </tr>)}
        </Table>
        <Paginator baseUrl={`/users`} pages={pages} current={searchParams.get("page") || 1} />
      </div>
    )
}

export function Create() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("user");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const saveUser = e => {
    e.preventDefault();
    Utils.api('users', {
      method: 'POST',
      body: {
        name: name,
        email: email,
        password: password,
        role: role,
      }
    }).then(response => {
      navigate('/users');
    }).catch(error => {
      setError(error.response.data);
    });
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>Crear usuario</h2>
        <Button tag={RNavLink} to='/users' color='link'>Regresar a la lista</Button>
      </div>
      { error?.message ? <Alert color='danger'>{ error.message }</Alert> : null }
      <Form onSubmit={ saveUser }>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="name">
                Nombre
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Escribe el nombre del usuario"
                type="text"
                value={ name }
                onChange={ e => setName(e.target.value) }
                invalid={ error.errors?.name }
              />
              { error?.errors?.name ? <FormFeedback>{ error?.errors?.name[0] }</FormFeedback> : null }
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="role">
                Rol
              </Label>
              <Input
                id="role"
                name="role"
                type="select"
                value={ role }
                onChange={ e => setRole(e.target.value) }
                invalid={ error.errors?.client }
              >
                <option value="user">Usuario</option>
                <option value="manager">Manager</option>
              </Input>
              { error?.errors?.client ? <FormFeedback>{ error?.errors?.client[0] }</FormFeedback> : null }
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="email">
                Correo electrónico
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Escribe el correo electrónico del usuario"
                type="text"
                value={ email }
                onChange={ e => setEmail(e.target.value) }
                invalid={ error.errors?.email }
              />
              { error?.errors?.email ? <FormFeedback>{ error?.errors?.email[0] }</FormFeedback> : null }
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="email">
                Contraseña
              </Label>
              <Input
                id="password"
                name="password"
                placeholder="Escribe la contraseña para el usuario"
                type="password"
                value={ password }
                onChange={ e => setPassword(e.target.value) }
                invalid={ error.errors?.password }
              />
              { error?.errors?.password ? <FormFeedback>{ error?.errors?.password[0] }</FormFeedback> : null }
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className='d-flex justify-content-end'>
            <Button color='primary'>Guardar</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export function Detail() {
  let { userId } = useParams();
  const [user, setUser] = useState({
    id: userId
  });
  

  useEffect(() => {
    Utils.api(`users/${userId}`, {
      method: 'GET'
    }).then((response) => {
      setUser(response);
    });
  }, []);

  return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2>{ user.name }</h2>
          <div>
            <Button tag={RNavLink} to={'/users/' + userId + '/edit'} color='warning'>Modificar</Button>
            <Button tag={RNavLink} to='/users' color='link'>Regresar a la lista</Button>
          </div>
        </div>
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink end tag={RNavLink} to={`/users/${userId}`}>
                  Información General
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RNavLink} to={`/users/${userId}/rates`}>
                  Tarifas
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className='mt-4'>
          <Routes>
            <Route exact path="/" element={<Information user={ user }/>} />
            <Route exact path="/rates" element={<Rates user={ user }/>} />
          </Routes>
        </div>
      </>
  )
}

function Information(props) {
  return (
    <div>
      <Row>
        <Col>
          <label className='text-muted small'>Nombre</label>
          <p>{ props.user.name }</p>
        </Col>
        <Col>
          <label className='text-muted small'>Rol</label>
          <p>{ props.user.role }</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className='text-muted small'>Correo Electrónico</label>
          <p>{ props.user.email }</p>
        </Col>
      </Row>
    </div>
  )
}

function Rates(props) {

  const [isAddRateModalOpen, setIsAddRateModalOpen] = useState(false)
  const [rates, setRates] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    Utils.api(`projects`, {
      method: 'GET'
    }).then((response) => {
      setProjects(response);
    });

    Utils.api(`users/${props.user.id}/rates`, {
      method: 'GET'
    }).then((response) => {
      setRates(response);
    });
  }, []);


  return (
    <>
      <div className='mb-4'>
        <div class='text-end'>
          <Button type='button' onClick={ () => setIsAddRateModalOpen(true) } color='primary'>Registrar tarifa</Button>
        </div>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th>Válida desde</th>
            <th>Válida hasta</th>
            <th>Tarifa</th>
            <th>Proyecto</th>
          </tr>
        </thead>
        <tbody>
          { rates.map(r => <tr>
            <td>{ r.valid_from }</td>
            <td>{ r.valid_to }</td>
            <td className='text-end'>{ r.rate }</td>
            <td>{ r.project ? r.project.name : <span className='text-muted'>Cualquier proyecto</span> }</td>
          </tr>) }
        </tbody>
      </Table>
      <AddRateModal didAddRate={ (rate) => {console.log(rate); setRates([rate].concat(rates)); } } projects={ projects } user={ props.user } isOpen={ isAddRateModalOpen } toggle={ () => setIsAddRateModalOpen(!isAddRateModalOpen) } />
    </>
  )
}

function AddRateModal(props) {
  
  const [error, setError] = useState({});
  const [rate, setRate] = useState(0);
  const [validFrom, setValidFrom] = useState();
  const [validTo, setValidTo] = useState();
  const [projectId, setProjectId] = useState("");

  const handleSaveRate = () => {

    let body = {
      valid_from: validFrom,
      valid_to: validTo,
      rate: rate
    }

    if (projectId !== "") {
      body.project_id = projectId
    }

    Utils.api(`users/${props.user.id}/rates`, {
      method: 'POST',
      body: body
    }).then((response) => {
      props.didAddRate(response)
      setError({});
      props.toggle();
    }).catch((error) => {
      setError(error.response.data);
    });
  }

  

  return (
    <Modal size='lg' isOpen={ props.isOpen } toggle={ props.toggle }>
      <ModalHeader toggle={ props.toggle }>Registrar tarifa</ModalHeader>
      <ModalBody>
        { error?.message ? <Alert color='danger'>{ error.message }</Alert> : null }
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="rate">
                  Tarifa
                </Label>
                <Input
                  placeholder="Tarifa"
                  type="number"
                  value={ rate }
                  onChange={ e => setRate(e.target.value) }
                  invalid={ error?.errors?.rate }
                />
                { error?.errors?.rate ? <FormFeedback>{ error?.errors?.rate[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="project_id">
                  Proyecto
                </Label>
                <Input
                  type="select"
                  value={ projectId }
                  onChange={ e => setProjectId(e.target.value) }
                >
                  <option value="">Cualquier proyecto</option>
                  { props.projects.map(p => <option value={ p.id }>{ p.name }</option>) }
                </Input>
                { error?.errors?.valid_to ? <FormFeedback>{ error?.errors?.valid_to[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="valid_from">
                  Válida desde
                </Label>
                <Input
                  type="date"
                  value={ validFrom }
                  onChange={ e => setValidFrom(e.target.value) }
                  invalid={ error?.errors?.valid_from }
                />
                { error?.errors?.valid_from ? <FormFeedback>{ error?.errors?.valid_from[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="valid_to">
                  Válida hasta
                </Label>
                <Input
                  type="date"
                  value={ validTo }
                  onChange={ e => setValidTo(e.target.value) }
                  invalid={ error?.errors?.valid_to }
                />
                { error?.errors?.valid_to ? <FormFeedback>{ error?.errors?.valid_to[0] }</FormFeedback> : null }
              </FormGroup>
            </Col>
            
          </Row>
          
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={ props.toggle }>
          Cancelar
        </Button>
        <Button color="primary" onClick={ handleSaveRate }>
          Guardar tarifa
        </Button>{' '}
      </ModalFooter>
    </Modal>
  )
}

export function Edit() {

  let { userId } = useParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("user");
  const [password, setPassword] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [error, setError] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    Utils.api(`users/${userId}`, {
      method: 'GET'
    }).then(response => {
      setName(response?.name)
      setEmail(response?.email)
      setRole(response?.role)
    });
  }, []);

  const saveUser = e => {
    e.preventDefault();

    let user = {
      name: name,
      email: email,
      role: role,
    };

    if (changePassword) {
      user.password = password
    }

    Utils.api(`users/${userId}`, {
      method: 'PUT',
      body: user
    }).then(response => {
      navigate(`/users/${userId}`);
    }).catch(error => {
      setError(error.response.data);
    });
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>Editar usuario</h2>
        <Button tag={RNavLink} to={`/users/${userId}`} color='link'>Regresar al detalle</Button>
      </div>
      { error?.message ? <Alert color='danger'>{ error.message }</Alert> : null }
      <Form onSubmit={ saveUser }>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="name">
                Nombre
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Escribe el nombre del usuario"
                type="text"
                value={ name }
                onChange={ e => setName(e.target.value) }
                invalid={ error.errors?.name }
              />
              { error?.errors?.name ? <FormFeedback>{ error?.errors?.name[0] }</FormFeedback> : null }
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="role">
                Rol
              </Label>
              <Input
                id="role"
                name="role"
                type="select"
                value={ role }
                onChange={ e => setRole(e.target.value) }
                invalid={ error.errors?.client }
              >
                <option value="user">Usuario</option>
                <option value="manager">Manager</option>
              </Input>
              { error?.errors?.client ? <FormFeedback>{ error?.errors?.client[0] }</FormFeedback> : null }
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="email">
                Correo electrónico
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Escribe el correo electrónico del usuario"
                type="text"
                value={ email }
                onChange={ e => setEmail(e.target.value) }
                invalid={ error.errors?.email }
              />
              { error?.errors?.email ? <FormFeedback>{ error?.errors?.email[0] }</FormFeedback> : null }
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <div class="form-check mb-2">
                <input class="form-check-input" type="checkbox" checked={changePassword} id="changePassword" onChange={ e => { setChangePassword(e.target.checked); setPassword(""); } }/>
                <label class="form-check-label" for="changePassword">
                  Modificar contraseña
                </label>
              </div>
              <Input
                  id="password"
                  name="password"
                  placeholder="Escribe la contraseña para el usuario"
                  type="password"
                  value={ password }
                  disabled={ !changePassword }
                  onChange={ e => setPassword(e.target.value) }
                  invalid={ error.errors?.password }
              />
              { error?.errors?.password ? <FormFeedback>{ error?.errors?.password[0] }</FormFeedback> : null }
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className='d-flex justify-content-end'>
            <Button color='primary'>Guardar</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}